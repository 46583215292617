import * as React from "react";
import Layout from "../components/layout";
import { Link } from "gatsby";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { getImage, StaticImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import SEO from "../components/seo";
const PageWrapper = styled.section``;

const MainHero = styled(BgImage)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  img {
    margin-bottom: 24px;
  }
`;

const NavLink = styled(Link)`
  font-family: "Brandon Grotesque";
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  background: rgba(243, 118, 73, 0.5);
  border: 6px solid #ffffff;
  width: 157px;
  height: 47px;
  margin-bottom: 24px;
  border-radius: 5px;
  text-decoration: none;
  text-align: center;
  line-height: 35px;

  @media screen and (max-width: 400px) {
    font-size: 15px;
    margin-bottom: 20px;
    width: 140px;
    line-height: 35px;
  }
`;
const HomeHero = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #e5e5e5;

  h1 {
    font-family: "Eames Century Modern";
    color: black;
    font-style: italic;
    font-weight: normal;
    font-size: 55px;
    padding: 0px 20vw;
    text-align: center;

    @media screen and (max-width: 675px) {
      font-size: 45px;
    }

    @media screen and (max-width: 600px) {
      font-size: 35px;
      padding: 0px;
      width: 75%;
    }

    @media screen and (max-width: 500px) {
      font-size: 30px;
    }

    @media screen and (max-width: 400px) {
      font-size: 25px;
    }
  }

  span {
    color: #f37549;
  }

  svg {
    margin-top: 72px;
  }
`;
const ContentWrapper = styled.section`
  position: relative;
  display: flex;
  background: #e5e5e5;
  max-width: 100vw;
  overflow: hidden;
  @media screen and (max-width: 1400px) {
    height: 1500px;
  }
  @media screen and (max-width: 1100px) {
    height: 1500px;
  }
  @media screen and (max-width: 900px) {
    height: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

const MapWrapper = styled.section`
  position: relative;
  z-index: 1;
  max-width: 100vw;
  #map {
    position: absolute;
  }
  @media screen and (max-width: 500px) {
    height: 1000px;
  }
`;

const HomeDesign = styled.section`
  position: absolute;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #f05051 0%,
    #f37549 45.31%,
    rgba(243, 117, 73, 0) 100%
  );
  width: 2000px;
  height: 1500px;
  right: -60%;
  z-index: 3;
  top: -10px;
  @media screen and (max-width: 900px) {
    display: flex;
    justify-content: center;
    margin: auto;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    left: 0;
  }
  @media screen and (max-width: 500px) {
    display: flex;
    justify-content: center;
    margin: auto;
    width: 100vw;
    height: 1000px;
    top: -100px;
    right: 0;
  }
`;

const HomeContent = styled.section`
  display: flex;
  flex-direction: column;
  z-index: 2;
  margin-left: 10%;
  margin-right: 10%;

  @media screen and (max-width: 900px) {
    margin-left: 0;
    margin-right: 0;
  }
  h2 {
    font-family: "Eames Century Modern";
    color: black;
    font-weight: normal;
    font-size: 25px;
    text-decoration-line: underline;
    color: #f37549;

    @media screen and (max-width: 1400px) {
      :not(:nth-of-type(1)) {
        color: white;
      }
    }

    @media screen and (max-width: 1100px) {
      font-size: 23px;
    }
    @media screen and (max-width: 900px) {
      width: 80%;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      :not(:nth-of-type(1)) {
        color: #f37549;
      }
    }
    @media screen and (max-width: 600px) {
      font-size: 22px;
    }

    @media screen and (max-width: 500px) {
      font-size: 21px;
    }

    @media screen and (max-width: 400px) {
      font-size: 20px;
    }
  }

  p {
    display: flex;
    flex-direction: column;
    font-family: "Brandon Text";
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    color: black;
    width: 35vw;
    margin-top: 48px;
    margin-bottom: 228px;

    @media screen and (max-width: 1400px) {
      margin-bottom: 124px;
    }

    @media screen and (max-width: 1100px) {
      font-size: 28px;
      margin-bottom: 62px;
    }
    @media screen and (max-width: 1020px) {
      width: 40vw;
    }
    @media screen and (max-width: 900px) {
      width: 80%;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 124px;
    }

    @media screen and (max-width: 600px) {
      font-size: 25px;
      width: 80%;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 72px;
      margin-top: 24px;
    }

    @media screen and (max-width: 500px) {
      font-size: 23px;
    }

    @media screen and (max-width: 400px) {
      font-size: 20px;
      margin-top: 16px;
    }
  }
`;

const HomeFooter = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #e5e5e5;
  max-width: 100vw;
  @media screen and (max-width: 775px) {
    height: 120vh;
  }
  @media screen and (max-width: 650px) {
    height: 100vh;
  }
  h1 {
    font-family: "Eames Century Modern";
    color: #f37549;
    font-style: italic;
    font-weight: normal;
    font-size: 80px;
    padding: 0px 20vw;
    text-align: center;

    @media screen and (max-width: 675px) {
      font-size: 60px;
      padding: 0px;
      width: 75%;
    }

    @media screen and (max-width: 600px) {
      font-size: 40px;
      margin-bottom: 12px;
    }

    @media screen and (max-width: 500px) {
      font-size: 35px;
    }

    @media screen and (max-width: 400px) {
      font-size: 30px;
    }
  }

  h2 {
    font-family: "Brandon Text";
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    color: black;
    padding: 0px 20vw;
    text-align: center;

    @media screen and (max-width: 675px) {
      font-size: 30px;
      padding: 0px;
      width: 80%;
    }

    @media screen and (max-width: 500px) {
      font-size: 25px;
    }

    @media screen and (max-width: 400px) {
      font-size: 20px;
    }
  }
`;
const Partners = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #e5e5e5;
  height: 650px;
  h1 {
    font-family: "Brandon Grotesque";
    font-weight: bold;
    font-size: 35px;
    color: #ef4459;
    margin-bottom:40px;
  }
  .picsFirst {
    height: 150px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 75px;
  }
  .rowchange1st {
    align-items: center;
    display: flex;
  }
  .rowchange2nd{
    display:flex;
  }
  .picsSecond {
    height: 150px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  @media screen and (max-width: 1324px) {
    
  height: 900px;
    h1{
      margin-bottom:40px;
    }
    padding-bottom:150px;
    .picsFirst {
      width: 750px;
      margin-bottom: 175px;
    }
    .picsSecond {
      width: 750px;
    }
    .rowchange1st {
      margin-top: 10px;
    }
    .rowchange2nd {
      margin-top: 40px;
    }
    @media screen and (max-width: 703px) {
    height:125vh;
    .picsFirst {
      width: 400px;
      margin-bottom: 420px;
    }
    .picsSecond {
      margin-top:40px;
      width: 450px;
      
    }
    .rowchange1st {
      display:flex;
      
      flex-direction: row;
      justify-content: center;
      flex-wrap:wrap;
    }
    .rowchange1st > * {
      margin-bottom:15px;
    }
    .rowchange2nd {
      display:flex;
      justify-content:center;
      flex-wrap:wrap;
      margin-top: 25px;
    }
    @media screen and (max-width: 446px) {
      height:2300px;
    h1{
      margin-bottom:20px;
      font-size:25px;
    }
    padding-bottom:150px;
    .picsFirst {
      width: 200px;
      margin-bottom: 1050px;
    }
    .picsSecond {
      margin-top:35px;
      width: 200px;
      
    }
    .rowchange1st {
      display:flex;
      
    flex-direction: row;
    justify-content: center;
      flex-wrap:wrap;
    }
    .rowchange1st > *{
      margin-bottom:20px;
    }
    .rowchange2nd {
      display:flex;
      justify-content:center;
      flex-wrap:wrap;
      margin-top: 20px;
    }
    .rowchange2nd > * {
      margin-bottom:20px;
    }
`;
const ContactLink = styled(Link)`
  background: white;
  border: 6px solid #f37549;
  font-family: "Brandon Grotesque";
  font-weight: bold;
  font-size: 20px;
  color: #f37549;
  width: 157px;
  height: 47px;
  margin-top: 40px;
  border-radius: 5px;

  text-decoration: none;
  text-align: center;
  line-height: 35px;

  @media screen and (max-width: 500px) {
    font-size: 18px;
  }

  @media screen and (max-width: 400px) {
    font-size: 15px;
  }
`;

function HomePage() {
  const { placeholderImage, SEOImg } = useStaticQuery(graphql`
    query HomeHeroBG {
      placeholderImage: file(relativePath: { eq: "HomePage/HeroImage.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1500
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            quality: 100
          )
        }
      }
      SEOImg: file(relativePath: { eq: "hero.jpg" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const pluginImage = getImage(placeholderImage);
  const backgroundFluidImageStack = [
    `linear-gradient(rgba(0,0,0, 0.0), rgba(0, 0, 0, 0.0))`,
    pluginImage,
  ];

  return (
    <Layout identifier="Home">
      <SEO title="Home" image={SEOImg.childImageSharp.fluid} />
      <PageWrapper>
        <MainHero image={backgroundFluidImageStack}>
          <StaticImage
            src="../images/OurWork/OurWorkLogoLAAN.png"
            placeholder="blurred"
            width={303.75}
            style={{ marginBottom: "2rem" }}
            alt=""
          ></StaticImage>
          <NavLink to="/who-we-are">WHO WE ARE</NavLink>
          <NavLink to="/our-work">OUR WORK</NavLink>
          <NavLink to="/projects">PROJECTS</NavLink>
          <NavLink to="/contact-us">CONTACT US</NavLink>
        </MainHero>
        <HomeHero>
          <h1>
            Many Filipinos have to{" "}
            <span>pay for medical bills with money they do not have. </span>
            With no ability to pay, many are{" "}
            <span>driven deeper into poverty.</span>
          </h1>
          <svg
            width="30"
            height="88"
            viewBox="0 0 30 88"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5858 87.4142C14.3668 88.1953 15.6332 88.1953 16.4142 87.4142L29.1421 74.6863C29.9232 73.9052 29.9232 72.6389 29.1421 71.8579C28.3611 71.0768 27.0948 71.0768 26.3137 71.8579L15 83.1716L3.68629 71.8579C2.90524 71.0768 1.63891 71.0768 0.857864 71.8579C0.0768156 72.6389 0.0768156 73.9052 0.857864 74.6863L13.5858 87.4142ZM13 0L13 86H17L17 0L13 0Z"
              fill="#F37549"
            />
          </svg>
        </HomeHero>

        <ContentWrapper>
          <HomeContent>
            <h2>OUR VISION</h2>
            <p>
              Project LAAN envisions a society where every Filipino, especially
              the poor and vulnerable, has adept knowledge on public health and
              is empowered to make informed and appropriate health decisions.
            </p>
            <h2>OUR MISSION</h2>
            <p>
              Our mission is to instill and promote public health advocacy,
              health literacy, and patient empowerment to our partner
              communities and members. <br /> <br />
              Through embodying service, empowerment, and creativity &
              innovation, Project LAAN envisions Filipino patients with the
              capacity to make informed health decisions.
            </p>

            <h2>CORE COMPETENCIES</h2>
            <p>
              <span>1. Public Health Advocacy</span>
              <span>2. Health Systems Proficiency</span>
              <span>3. Health Communication</span>
            </p>
          </HomeContent>
          <MapWrapper>
            <StaticImage
              src="../images/HomePage/Map.png"
              placeholder="map"
              style={{ zIndex: "5" }}
              quality={100}
              alt=""
              id="map"
            ></StaticImage>
            <HomeDesign></HomeDesign>
          </MapWrapper>
        </ContentWrapper>
        <HomeFooter>
          <h1>HEALTH FOR ALL CAN BE A REALITY</h1>
          <h2>
            Here in Project LAAN, our dedicated team of members continue to
            fight poverty through health. Through health advocacy and literacy,
            we can achieve Universal Health Coverage: a​ll people obtaining
            quality health services, without financial burden.
          </h2>
          <ContactLink to="/contact-us">CONTACT US</ContactLink>
        </HomeFooter>
        <Partners>
          <h1> YEAR-LONG PARTNERS</h1>
          <div className="picsFirst">
            <StaticImage
              src="../images/HomePage/Baguio.png"
              placeholder="blurred"
              alt=""
              style={{
                height: "150px",
                marginLeft: "10px",
                marginRight: "10px",
              }}
              quality={90}
            ></StaticImage>

            <StaticImage
              src="../images/HomePage/Sisig.png"
              placeholder="blurred"
              alt=""
              style={{
                height: "150px",
                marginLeft: "10px",
                marginRight: "10px",
              }}
              quality={90}
            ></StaticImage>
            <StaticImage
              src="../images/HomePage/Barks.png"
              placeholder="blurred"
              alt=""
              style={{
                height: "150px",
                marginLeft: "10px",
                marginRight: "10px",
              }}
              quality={90}
            ></StaticImage>
            <StaticImage
              src="../images/HomePage/Bibimbud.png"
              placeholder="blurred"
              alt=""
              style={{
                height: "150px",
                width: "150px",
                marginLeft: "10px",
                marginRight: "10px",
              }}
              quality={90}
            ></StaticImage>
            <section className="rowchange1st">
              <StaticImage
                src="../images/HomePage/Castle.png"
                placeholder="blurred"
                alt=""
                style={{
                  height: "150px",
                  width: "150px",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
                quality={90}
              ></StaticImage>
              <StaticImage
                src="../images/HomePage/Certified.png"
                placeholder="blurred"
                alt=""
                id="certified"
                style={{
                  width: "150px",
                  height: "150px",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
                quality={90}
              ></StaticImage>
              <StaticImage
                src="../images/HomePage/Guiltless.png"
                placeholder="blurred"
                alt=""
                style={{
                  height: "122.24px",
                  width: "120px",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
                quality={90}
              ></StaticImage>
              <StaticImage
                src="../images/HomePage/Jacinto.png"
                placeholder="blurred"
                alt=""
                style={{
                  height: "122px",
                  width: "126px",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
                quality={90}
              ></StaticImage>
            </section>
          </div>
          <div className="picsSecond">
            <StaticImage
              src="../images/HomePage/Kleo.png"
              placeholder="blurred"
              alt=""
              style={{
                height: "122px",
                marginLeft: "10px",
                marginRight: "10px",
              }}
              quality={90}
            ></StaticImage>
            <StaticImage
              src="../images/HomePage/Kynd.png"
              placeholder="blurred"
              alt=""
              style={{
                height: "150px",
                marginLeft: "10px",
                marginRight: "10px",
              }}
              quality={90}
            ></StaticImage>
            <StaticImage
              src="../images/HomePage/L.png"
              placeholder="blurred"
              alt=""
              style={{
                height: "131.57px",
                marginLeft: "10px",
                marginRight: "10px",
              }}
              quality={90}
            ></StaticImage>

            <StaticImage
              src="../images/HomePage/LaMar.png"
              placeholder="blurred"
              alt=""
              style={{
                height: "122px",
                marginLeft: "10px",
                marginRight: "10px",
              }}
              quality={90}
            ></StaticImage>
            <section className="rowchange2nd">
              <StaticImage
                src="../images/HomePage/Maddie.png"
                placeholder="blurred"
                alt=""
                style={{
                  height: "122px",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
                quality={90}
              ></StaticImage>
              <StaticImage
                src="../images/HomePage/Secrets.png"
                placeholder="blurred"
                alt=""
                style={{
                  height: "122px",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
                quality={90}
              ></StaticImage>
              <StaticImage
                src="../images/HomePage/Sunny.png"
                placeholder="blurred"
                alt=""
                style={{
                  height: "122px",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
                quality={90}
              ></StaticImage>
            </section>
          </div>
        </Partners>
      </PageWrapper>
    </Layout>
  );
}

export default HomePage;
